import {getRequest, postRequest, monitorGateway,urlTitle} from "@/utils/request";
import qs from "qs";

window.qs = qs;
// 驾驶舱-网 electricity
export const cockpitApi = {
    // 告警情况统计
    queryAlarmStatistic: () => {
        return getRequest(`${monitorGateway}/cockpitel/alarm`);
    },
    // 24小时实时负载统计
    queryLoadStatistic: () => {
        return getRequest(`${monitorGateway}/cockpitel/load`);
    },
    // 计划停电情况统计
    queryPowerCut: () => {
        return getRequest(`${monitorGateway}/cockpitel/power/plan`);
    },
    // 电能质量情况统计
    queryPowerQuality: () => {
        return getRequest(`${monitorGateway}/cockpitel/quality`);
    },

    // 实时数据
    queryRealData: () => {
        return getRequest(`${monitorGateway}/cockpitel/real`);
    },
    // 抢修/维修情况统计
    queryTaskStatistic: () => {
        return getRequest(`${monitorGateway}/cockpitel/task`);
    },
    // 获取总用电量（GWh）
    queryTotalPower: () => {
        return getRequest(`${monitorGateway}/cockpitel/total/electricity`);
    },
    // 近七日用电趋势
    queryWeekElectricity: () => {
        return getRequest(`${monitorGateway}/cockpitel/trend`);
    },
    // 各中心新旧设备占比
    queryDevice: () => {
        return getRequest(`${monitorGateway}/cockpitel/equipment`);
    },
    // 尖峰平谷
    queryPeriodTime: (params) => {
        return getRequest(`${monitorGateway}/cockpitel/peak`, params);
    },

    //场站列表
    stationList: (params) => {
        return getRequest(`${urlTitle}/storage/drive/station/list`, params);
    },
    selectInfoById: (params) => {
        return getRequest(`${urlTitle}/storage/drive/station/selectInfoById`, params);
    },
    selectListById: (params) => {
        return postRequest(`${urlTitle}/storage/drive/station/selectListById`, params);
    },

    overviewInfo: (params) => {
        return getRequest(`${urlTitle}/storage/drive/overview/info`, params);
    },
    realTimeChargeDischargeRate: (params) => {
        return getRequest(`${urlTitle}/storage/drive/realTimeChargeDischargeRate/info`, params);
    },
    incomeSituationInfo: (params) => {
        return getRequest(`${urlTitle}/storage/drive/incomeSituation/info`, params);
    },

    //驾驶舱源调用
    //场站查询
    powergenStationList: (params) => {
        return getRequest(`${urlTitle}/powergen/drive/powergenStationList/list`, params);
    },
    powergenStationListById: (params) => {
        return postRequest(`${urlTitle}/powergen/drive/powergenStationList/selectListById`, params);
    },
    powergenSelectInfoById: (params) => {
        return getRequest(`${urlTitle}/powergen/drive/powergenStationList/selectInfoById`, params);
    },

    //综合数据
    powergenComprehensiveData: (params) => {
        return getRequest(`${urlTitle}/powergen/drive/powergenComprehensiveData/info`, params);
    },
    //发电情况
    powergenPowerGenerationStatus: (params) => {
        return getRequest(`${urlTitle}/powergen/drive/powergenPowerGenerationStatus/info`, params);
    },
    //7日发电预测量
    powergenSevenDayPowerGenerationForecast: (params) => {
        return getRequest(`${urlTitle}/powergen/drive/powergenSevenDayPowerGenerationForecast/info`, params);
    },
    //光伏发电使用情况
    powergenPhotovoltaicPowerUsage: (params) => {
        return getRequest(`${urlTitle}/powergen/drive/powergenPhotovoltaicPowerUsage/info`, params);
    },

    //驾驶舱储
    //当日充放电量
    storageDayElectricity: (params) => {
        return getRequest(`${urlTitle}/storage/drive/storageDayElectricity/info`, params);
    },

};
